import { USER_INFO_API, USER_SETTING } from '../constants/apiUrl';
import axiosInstance from '../AxiosInstance';

export const updateUserInfo = async () => {
  try {
    const response = await getUserInfo();
    const { user: profileInfo, setting: settingInfo, crownSetting, permissions: permissionsInfo } = response;
    const userInfo = {
      profileInfo,
      settingInfo,
      crownSetting,
      permissionsInfo,
    };

    return { userInfo };
  } catch (err) {
    console.error(err);
    throw err;
  }
};

export const getUserInfo = () => axiosInstance.get(USER_INFO_API).then((res) => res.data);

export const getSystemSetting = async () => axiosInstance.get(USER_SETTING).then((res) => res.data);
