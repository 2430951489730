import { detect } from 'detect-browser';

export const isMobileEnv = () => {
  const browser = detect();
  const systemOS = browser?.os;

  const isMobileWeb =
    navigator.userAgent.match(/ipad|iphone/i) !== null || navigator.userAgent.match(/Android/i) !== null;
  const isAppleIpadOS = systemOS === 'Mac OS' && navigator.maxTouchPoints > 0;
  const isAndroidSamsungBrowser = navigator.userAgent.match(/SamsungBrowser/i) !== null;
  const isMobile: boolean = isMobileWeb || isAppleIpadOS || isAndroidSamsungBrowser;
  return isMobile;
};
