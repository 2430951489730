import { atom } from 'recoil';

export const globalDialogueStatusAtom = atom({
  key: 'globalDialogue',
  default: 0,
});

export const GLOBAL_DIALOGUE_STATE = {
  REFRESH_TOKEN_INVALID: 1,
};
