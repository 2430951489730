import {
  Button,
  Dialogue,
  DialogueActions,
  DialogueContent,
  DialogueTitle,
  Ricon,
  theme,
  Typography,
} from '@imago-cloud/design-system';
import { Stack } from '@mui/material';
import React from 'react';
import { useResponsive } from '../../hooks';
import { useTranslation } from 'react-i18next';

export const RefreshTokenInvalidDialogue = () => {
  const { t } = useTranslation();

  const { responsiveCSS } = useResponsive();
  const onCloseButtonClick = () => {
    window.close();
  };
  return (
    <Dialogue open>
      <DialogueTitle>
        <Stack gap="8px" direction="row" alignItems="center">
          <Ricon icon="ri-alert-fill" svgProps={{ width: 24, height: 24, fill: theme.palette.error.main }} />
          {t('dialog_session_expired.title')}
        </Stack>
      </DialogueTitle>
      <DialogueContent sx={{ px: '28px', pb: '32px' }}>
        <Typography variant={responsiveCSS('Body18', 'Body16')}>{t('dialog_session_expired.body')}</Typography>
      </DialogueContent>
      <DialogueActions>
        <Button
          id="sessionExpiredDialogueCloseBtn"
          variant="contained"
          size={responsiveCSS('36', '48')}
          color="primary"
          sx={{ minWidth: '100px' }}
          onClick={onCloseButtonClick}
        >
          {t('dialog_session_expired.btn_ok')}
        </Button>
      </DialogueActions>
    </Dialogue>
  );
};
