import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';

import de from './locales/de/no_filename.json';
import en from './locales/en/no_filename.json';
import es from './locales/es/no_filename.json';
import fr from './locales/fr/no_filename.json';
import it from './locales/it/no_filename.json';
import ja from './locales/ja/no_filename.json';
import ko from './locales/ko/no_filename.json';
import pt from './locales/pt/no_filename.json';
import zh_cn from './locales/zh_cn/no_filename.json';

export const resources = {
  en: { lang: en },
  de: { lang: de },
  es: { lang: es },
  fr: { lang: fr },
  it: { lang: it },
  ja: { lang: ja },
  ko: { lang: ko },
  pt: { lang: pt },
  zh_cn: { lang: zh_cn },
} as const;

export const defaultNS = 'lang';

i18next.use(initReactI18next).init({
  resources,
  defaultNS,
  interpolation: {
    escapeValue: false,
  },
});
