import React from 'react';
import ReactDOM from 'react-dom/client';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import App from './App';
import { BrowserRouter } from 'react-router-dom';

import './font.css';
import GTMHelmet from './lib/GTMHelmet';
import { HelmetProvider } from 'react-helmet-async';
import { AuthProvider } from './auth/AuthProvider';
import instance, { AxiosInterceptor } from './AxiosInstance';
import { GlobalAuthProvider } from '@imago-cloud/auth-client';

const queryClient = new QueryClient({
  defaultOptions: {
    mutations: {
      networkMode: 'offlineFirst',
    },
    queries: {
      networkMode: 'offlineFirst',
      retryDelay: 10000,
      retry: false,
    },
  },
});

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <HelmetProvider>
        <BrowserRouter>
          <GTMHelmet />
          <AxiosInterceptor>
            <GlobalAuthProvider instance={instance}>
              <AuthProvider>
                <App />
              </AuthProvider>
            </GlobalAuthProvider>
          </AxiosInterceptor>
        </BrowserRouter>
      </HelmetProvider>
    </QueryClientProvider>
  </React.StrictMode>,
);
