import {Button, Ricon, theme, Typography} from '@imago-cloud/design-system';
import { Stack } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import {useResponsive} from "../hooks";

const NetworkErrorPage = () => {
  const navigate = useNavigate();
  const { responsiveCSS } = useResponsive();

  // TODO: 이 페이지 이동은 좋은데, 회복됐을 때 돌아올 방법을 찾아야 한다. 혹은 선별적으로 이동하거나.
  // useEffect(() => {
  //   if (navigator.onLine) {
  //     navigate('/');
  //   }
  // }, []);
  // https: 에서는 없어도 됨 <- 상위에서 accessToken 받아와야 함.

  return (
    <Stack sx={{ mt: responsiveCSS('300px','140px') ,mx:'16px'}} alignItems="center">
      <Ricon icon="ri-alert-fill" svgProps={{ width:80, height:80,fill:theme.palette.error.main}} />
      <Typography variant={responsiveCSS('H36', 'H18')} sx={{ my:'12px' }}>
        Network Error
      </Typography>
      <Stack sx={{display:'flex',mb: '40px',textAlign:'center',width:responsiveCSS('377px','311px')}}>
      <Typography
        variant="Body18"
        component={'div'}

      >
        Unable to connect to the Internet.
      </Typography>
      <Typography variant="Body18" component={'div'} >
        Please check your connection and try again.
      </Typography>
      </Stack>
      <Button size='48' variant="outlined" color="grey" onClick={() => window.history.back()}>
        Refresh
      </Button>
    </Stack>
  );
};

export default NetworkErrorPage;
