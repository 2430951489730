import { datadogRum, RumInitConfiguration } from '@datadog/browser-rum';

const DATA_DOG_RUM_INIT_CONFIGURATION_RELEASE: RumInitConfiguration = {
  applicationId: 'ea9606b1-f843-4a9a-aed2-25276d2f4dd4',
  clientToken: 'pub60b2bd7a2ecf54c16ad91d983b931e1b',
  site: 'us3.datadoghq.com',
  service: 'dentbird-payment-client',
  env: 'release',
  // Specify a version number to identify the deployed version of your application in Datadog
  // version: '1.0.0',
  sessionSampleRate: 100,
  sessionReplaySampleRate: 100,
  trackUserInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  defaultPrivacyLevel: 'mask-user-input',
  trackSessionAcrossSubdomains: true,
};
const DATA_DOG_RUM_INIT_CONFIGURATION_QA: RumInitConfiguration = {
  applicationId: 'ea9606b1-f843-4a9a-aed2-25276d2f4dd4',
  clientToken: 'pub60b2bd7a2ecf54c16ad91d983b931e1b',
  site: 'us3.datadoghq.com',
  service: 'dentbird-payment-client',
  env: 'qa',
  // Specify a version number to identify the deployed version of your application in Datadog
  // version: '1.0.0',
  sessionSampleRate: 100,
  sessionReplaySampleRate: 100,
  trackUserInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  defaultPrivacyLevel: 'mask-user-input',
  trackSessionAcrossSubdomains: true,
};
const REACT_ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT || "release";
export const dataDogRumInit = () => {
  switch (REACT_ENVIRONMENT) {
    case 'release':{
      datadogRum.init(DATA_DOG_RUM_INIT_CONFIGURATION_RELEASE);
      datadogRum.startSessionReplayRecording();
      break;
    }
    case 'qa': {
      datadogRum.init(DATA_DOG_RUM_INIT_CONFIGURATION_QA);
      datadogRum.startSessionReplayRecording();
      break;
    }
    default:
      break;
  }
};
