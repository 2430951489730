import { Stack } from '@mui/material';
import React from 'react';
import { Loading } from '@imago-cloud/design-system';

const CustomLoading = () => {
  return (
    <Stack direction="column" justifyContent="center" alignItems="center" style={{ height: '100vh' }}>
      <Loading type='basic' size='44' />
    </Stack>
  );
};

export default CustomLoading;
