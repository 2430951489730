import ImagoEvent from '@imago-cloud/action-log';
import { isImagoworks } from '../util/general';
import { useAuthContext } from '../auth/useAuthContext';

// Transaction 이벤트 추가되면 여기 추가하고,
// 'flow' 단계가 추가되면 커스텀 훅을 하나 더 만들고, 합성 커스텀 훅을 만들어 사용한다.

type chargeCreditStepType = Parameters<typeof ImagoEvent.transaction.chargeCredit>[0]['chargeCreditStep'];
type dynamicUserEventValue = {
  balanceChanged?: number | 0;
  balanceFinal?: number | 0;
  transactionId?: string | '';
  voucherUnit?: number | 0;
  chargeCreditStep: chargeCreditStepType;
};

const useTransactionImagoEvent = () => {
  const {
    userInfo: { tenantId, userId },
    emailFullName: { email },
  } = useAuthContext();

  const handleChargeCreditEvent = ({
    voucherUnit,
    chargeCreditStep,
    balanceChanged,
    balanceFinal,
    transactionId,
  }: dynamicUserEventValue) => {
    ImagoEvent.transaction.chargeCredit({
      // static
      userId,
      isImagoworks: isImagoworks(email),
      tenantId,
      sourceType: 'voucher',
      voucherChannel: 'dentbird',
      giftcardChannel: 'dentbird',
      method: 'paymentwall',
      discountInCredit: 0,
      totalPriceInCredit: 0,
      // creditWalletId: ??
      // dynamic
      balanceChanged: balanceChanged ?? 0,
      balanceFinal: balanceFinal ?? 0,
      transactionId: transactionId ?? '',
      voucherUnit: voucherUnit ?? 0,
      chargeCreditStep, // 항상 가변적
    });
  };

  return { handleChargeCreditEvent };
};

export default useTransactionImagoEvent;
