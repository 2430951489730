import { useEffect } from 'react';

export const useAvoidExit = (hasChanged: boolean = true) => {
  const handleBeforeunload = (e: BeforeUnloadEvent) => {
    if (hasChanged) {
      e.preventDefault();
      e.returnValue = '';
      return '';
    }
    return undefined;
  };

  useEffect(() => {
    window.addEventListener('beforeunload', handleBeforeunload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeunload);
    };
  }, [hasChanged]);
};
