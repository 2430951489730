import { useMemo, useState } from 'react';
import { IPaymentWidgetResponse, useGetCreditBalance, useGetCreditVouchers, usePostPaymentWidget } from '../api';
import { ICreditVoucher } from '../types/creditVoucher';
import { isMobileEnv } from '../util/isMobileEnv';
import useTransactionImagoEvent from './useTransactionImagoEvent';
import { useNavigate } from 'react-router-dom';
import { PAYMENT_WALL_PAGE_URL } from '../constants/pageUrls';

export type useSelectionHook = ReturnType<typeof useSelectionHook>;

export const useSelectionHook = () => {
  const navigate = useNavigate();
  const [selectedCreditItem, setSelectedCreditItem] = useState<ICreditVoucher | null>(null);

  // Imago Log
  const { handleChargeCreditEvent } = useTransactionImagoEvent();

  const { data: creditVoucherList, isLoading: isCreditVoucherLoading } = useGetCreditVouchers();
  const { data: rawCreditBalance, isLoading: isCreditBalanceLoading } = useGetCreditBalance();
  const balance = rawCreditBalance?.data.balance ?? 0;
  const { mutate: openPaymentWall, data } = usePostPaymentWidget();

  const isLoading = isCreditVoucherLoading || isCreditBalanceLoading;

  const onSelectItemClick = (item: ICreditVoucher) => {
    setSelectedCreditItem(item);
    handleChargeCreditEvent({
      voucherUnit: item.creditAmount,
      chargeCreditStep: 'amountClick',
    });
  };

  const onSubmit = () => {
    openPaymentWall(
      {
        creditVoucherId: selectedCreditItem?.creditVoucherId ?? '',
      },
      {
        onSuccess: ({ data }: IPaymentWidgetResponse) => {
          const { widgetUrl, htmlCode } = data;
          const invoiceId = new URL(widgetUrl).searchParams.get('invoiceId') ?? '';

          handleChargeCreditEvent({
            chargeCreditStep: 'continuePayment',
            transactionId: invoiceId,
          });

          navigate(PAYMENT_WALL_PAGE_URL, { state: { invoiceId, selectedCreditItem, widgetUrl } });
        },
      },
    );
  };

  const onBackClick = () => {
    setSelectedCreditItem(null);
    handleChargeCreditEvent({
      chargeCreditStep: 'backClick',
    });
  };
  const isMobile = useMemo(() => {
    return isMobileEnv() && document.referrer;
  }, []);

  const onClose = () => {
    if (isMobile) {
      history.back();
    } else {
      window.close();
    }
  };

  return {
    creditVoucherList,
    balance,
    selectedCreditItem,
    onBackClick,
    onSubmit,
    onSelectItemClick,
    isLoading,
    onClose,
  };
};
