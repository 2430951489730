import { Box, Stack } from '@mui/material';
import React from 'react';
import { useResponsive } from '../hooks';

const Layout = ({ children }: { children: React.ReactNode }) => {
  const { responsiveCSS } = useResponsive();

  return (
    <Stack sx={{ width: '100%', alignItems: 'center' }}>
      <Box sx={{ minWidth: '343px', maxWidth: '520px', width: responsiveCSS('520px', '343px') }}>{children}</Box>
    </Stack>
  );
};

export default Layout;
