import React from 'react';
import { Theme } from './theme';
import { ThemeProvider } from '@imago-cloud/design-system';
import Router from './Router';
import { RecoilRoot } from 'recoil';
import { GlobalDialogueSwitch } from './compositions/GlobalDialogueSwitch';
import { dataDogRumInit } from './lib/datadog';
import './i18n';

dataDogRumInit();
function App() {
  return (
    <RecoilRoot>
      <ThemeProvider theme={Theme()}>
        <GlobalDialogueSwitch />
        <Router />
      </ThemeProvider>
    </RecoilRoot>
  );
}

export default App;
