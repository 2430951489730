import React from 'react';
import { globalDialogueStatusAtom } from '../recoil/globalDialogueStatusAtom';
import { useRecoilValue } from 'recoil';
import { RefreshTokenInvalidDialogue } from './GlobalDialogue/RefreshTokenInvalidDialogue';
import { Stack } from '@mui/material';
export const GlobalDialogueSwitch = () => {
  const globalDialogueValue = useRecoilValue(globalDialogueStatusAtom);

  const dialogueSwitch = (type: number) => {
    switch (type) {
      case 1:
        return <RefreshTokenInvalidDialogue />;

      default:
        return null;
    }
  };

  return <Stack>{dialogueSwitch(globalDialogueValue)}</Stack>;
};
