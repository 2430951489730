import { Button, Ricon, Typography } from '@imago-cloud/design-system';
import { grey } from '@imago-cloud/design-system/lib/esm/colors';
import { Box, Stack } from '@mui/material';
import React from 'react';

import { useResponsive, useSelectionHook } from '../hooks';
import { ICreditVoucher } from '../types/creditVoucher';
import { useRecoilValue } from 'recoil';
import { userStateAtom } from '../recoil/userStateAtom';
import { useTranslation } from 'react-i18next';
import { Layout } from '../components';

interface ChooseCreditContentProps {
  onselectItemClick: useSelectionHook['onSelectItemClick'];
  creditVoucherList: ICreditVoucher[];
  onClose: useSelectionHook['onClose'];
  balance: useSelectionHook['balance'];
}

export const ChooseCreditContent = ({
  onselectItemClick,
  creditVoucherList,
  onClose,
  balance,
}: ChooseCreditContentProps) => {
  const { responsiveCSS } = useResponsive();

  const { email, fullName } = useRecoilValue(userStateAtom) || {};
  const { t } = useTranslation();

  return (
    <Layout>
      <Stack sx={{ position: 'relative' }}>
        <Stack sx={{ mt: responsiveCSS('28px', '14px') }} justifyContent={'space-between'}>
          <Typography variant={'H24'} sx={{ marginBottom: '28px' }}>
            {t('purchase_credit_dialog.title')}
          </Typography>
          <Typography variant={'H18'} color={'text.primary'} sx={{ marginBottom: '4px' }}>
            {fullName}
          </Typography>
          <Typography variant={'Body18'} color={'primary'}>
            {email}
          </Typography>
        </Stack>

        <Stack
          gap={'4px'}
          sx={{ mt: '28px', mb: responsiveCSS('12px', '16px'), flexDirection: 'row', justifyContent: 'space-between' }}
        >
          <Typography variant="H18">{t('purchase_credit_dialog.subtitle_your_balance')}</Typography>
          <Typography variant={'H18'}>{t('purchase_credit_dialog.balance', { count: balance })}</Typography>
        </Stack>
        <Stack gap={'12px'}>
          {creditVoucherList.map((item, index) => {
            return (
              <Box
                id={`select_credit-${index}`}
                key={item.creditVoucherId}
                onClick={() => onselectItemClick(item)}
                sx={{
                  border: (t) => `1px solid ${t.palette.grey[200]}`,
                  borderRadius: '8px',
                  maxWidth: '520px',
                  height: '68px',
                  padding: '20px 8px 20px 20px',
                  '&:hover': {
                    backgroundColor: (t) => t.palette.action.hover,
                    cursor: 'pointer',
                  },
                  '& *': {
                    pointerEvents: 'none',
                  },
                }}
              >
                <Stack direction={'row'} justifyContent={'space-between'}>
                  <Stack direction={'row'} gap={'8px'}>
                    <Typography variant={'H18'}>{item.creditAmount}</Typography>
                    <Typography variant={'Body18'}>{t('purchase_credit_dialog.credit_text')}</Typography>
                  </Stack>
                  <Stack direction={'row'}>
                    <Typography variant={'H18'} color={'primary.main'}>
                      ${item.voucherPrice.toLocaleString()}
                    </Typography>
                    <Ricon icon={'ri-arrow-right-s-line'} svgProps={{ width: 24, height: 24, fill: grey[500] }} />
                  </Stack>
                </Stack>
                {item?.bonusAmount !== 0 ? (
                  <Typography variant="Body14" color={'text.secondary'}>
                    <Typography variant={'Subtitle_Semibold14'}>Bonus {item.bonusAmount.toLocaleString()}</Typography>
                    {t('purchase_credit_dialog.credit_text')}
                  </Typography>
                ) : (
                  <></>
                )}
              </Box>
            );
          })}
        </Stack>
        <Button
          id="firstPageCancelCreditBtn"
          variant="outlined"
          size={responsiveCSS('60', '48')}
          color="grey"
          sx={{ width: '100%', mt: responsiveCSS('28px', '24px') }}
          type={'submit'}
          onClick={onClose}
        >
          {t('purchase_credit_dialog.btn_cancel_purchase')}
        </Button>
      </Stack>
    </Layout>
  );
};

export default ChooseCreditContent;
