import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import { userStateAtom } from '../recoil/userStateAtom';
import { useAuthContext } from '../auth/useAuthContext';
import CustomLoading from '../compositions/CustomLoading';

const AuthHoc = () => {
  const setUserState = useSetRecoilState(userStateAtom);
  const { isLoading, isAuthorised, emailFullName } = useAuthContext();

  useEffect(() => {
    if (!emailFullName) return;
    setUserState({ email: emailFullName.email, fullName: emailFullName.fullName });
  }, [emailFullName]);

  if (isLoading || !isAuthorised) {
    return <CustomLoading />;
  }
  return <Outlet />;
};

export default AuthHoc;
