import { Stack } from '@mui/material';
import React, { useEffect } from 'react';
import { useResponsive } from '../hooks';
import { Button, Link, Ricon, theme, Typography } from '@imago-cloud/design-system';
import { isMobileEnv } from '../util/isMobileEnv';
import { useRecoilValue } from 'recoil';
import { paymentWallStatusAtom } from '../recoil/paymentWallStatusAtom';
import { useNavigate } from 'react-router-dom';
import { ACCESS_DENIED_PAGE_URL } from '../constants/pageUrls';
import { Trans, useTranslation } from 'react-i18next';
import { Layout } from '../components';

export const SuccessWaitPage = () => {
  const navigate = useNavigate();
  const { responsiveCSS } = useResponsive();
  const paymentWallStatus = useRecoilValue(paymentWallStatusAtom);
  const { t } = useTranslation();

  const onConfirmButtonClick = () => {
    if (isMobileEnv()) history.go(-(window.history.length - 1));
    // 모바일 환경에선 very first history page로 이동
    else window.close();
  };

  useEffect(() => {
    if (!paymentWallStatus) navigate(ACCESS_DENIED_PAGE_URL, { replace: true });
    else return;
  }, [paymentWallStatus]);

  return (
    <Layout>
      <Stack sx={{ mt: '28px', padding: responsiveCSS('0px 24px', '0px') }} alignItems={'center'}>
        <Ricon icon="ri-error-warning-line" svgProps={{ width: 80, height: 80, fill: theme.palette.blue[500] }} />
        <Stack
          gap={'8px'}
          alignItems={'center'}
          sx={{ textAlign: 'center', mt: '20px', mb: responsiveCSS('8px', '4px') }}
        >
          <Typography variant={responsiveCSS('H36', 'H24')}>{t('dialog_purchase_processing.title')}</Typography>
        </Stack>
        <Typography
          variant={responsiveCSS('Subtitle_Semibold18', 'Subtitle_Semibold14')}
          sx={{ textAlign: 'center' }}
          color={theme.palette.text.thirdary}
        >
          <Trans
            i18nKey="dialog_purchase_processing.body"
            components={[<Link href={process.env.REACT_APP_HELP_CENTER_URL} target={'_blank'} />]}
          />
        </Typography>
      </Stack>
      <Button
        id="creditSuccessWaitConfirmBtn"
        variant="contained"
        size={responsiveCSS('60', '48')}
        color="blue"
        sx={{ width: '100%', mt: responsiveCSS('28px', '24px') }}
        onClick={onConfirmButtonClick}
      >
        {t('dialog_purchase_processing.btn_confirm')}
      </Button>
    </Layout>
  );
};
