import React, { lazy, Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import AuthHoc from './hoc/AuthHoc';
import {
  ACCESS_DENIED_PAGE_URL,
  NETWORK_ERROR_PAGE_URL,
  PAYMENT_FAILED_PAGE_URL,
  PAYMENT_SUCCESS_PAGE_URL,
  PAYMENT_WALL_PAGE_URL,
  ROOT_PAGE_URL,
  SUCCESS_WAIT_PAGE_URL,
} from './constants/pageUrls';
import AccessDeniedPage from './page/AccessDeniedPage';
import { SuccessWaitPage } from './page/SuccessWaitPage';
import NetworkErrorPage from './page/NetworkErrorPage';
import CustomLoading from './compositions/CustomLoading';
import PaymentSelectionPage from './page/PaymentSelectionPage';

const Router = () => {
  return (
    <Suspense fallback={<CustomLoading />}>
      <Routes>
        <Route element={<AuthHoc />}>
          <Route path={ROOT_PAGE_URL} element={<PaymentSelectionPage />} />
          <Route path={PAYMENT_WALL_PAGE_URL} element={<PaymentWallPage />} />
          <Route path={PAYMENT_SUCCESS_PAGE_URL} element={<PaymentSuccessPage />} />
          <Route path={PAYMENT_FAILED_PAGE_URL} element={<PaymentFailedPage />} />
        </Route>
        {/* Without AuthHoc */}
        <Route path={SUCCESS_WAIT_PAGE_URL} element={<SuccessWaitPage />} />
        <Route path={ACCESS_DENIED_PAGE_URL} element={<AccessDeniedPage />} />
        <Route path={NETWORK_ERROR_PAGE_URL} element={<NetworkErrorPage />} />
      </Routes>
    </Suspense>
  );
};

export default Router;
const PaymentWallPage = lazy(() => import('./page/PaymentWallIframePage'));
const PaymentSuccessPage = lazy(() => import('./page/PaymentSuccessPage'));
const PaymentFailedPage = lazy(() => import('./page/PaymentFailedPage'));
