import {Button, Typography, theme, Ricon} from '@imago-cloud/design-system';
import { Stack } from '@mui/material';
import React from 'react';
import { useResponsive } from '../hooks';

const AccessDeniedPage = () => {
  const onGoToDentbirdButtonClick = () => {
    window.location.replace(process.env.REACT_APP_IMAGOWORKS_HOST_DOMAIN || '');
  };
  const { responsiveCSS } = useResponsive();

  return (
    <Stack sx={{ mt: '160px' }} alignItems="center">
      <Ricon icon="ri-alert-fill" svgProps={{width:80 ,height:80,fill:theme.palette.error.main}}  />
      <Typography variant={responsiveCSS('H36', 'H18')} sx={{ margin: '12px 0px' }}>
        Access denied
      </Typography>

      <Typography
        variant="Body18"
        sx={{
          width: '400px',
          justifyContent: 'center',
          alignItems: 'center',
          display: 'flex',
          textAlign: 'center',
          margin: '0px 0px 40px 0px',
        }}
      >
        You can't access this page.
      </Typography>
      <Button
        variant="outlined"
        color="grey"
        onClick={onGoToDentbirdButtonClick}
        size="48"
        sx={{
          ...theme.typography.Button_Bold16,
        }}
      >
        Go to Dentbird
      </Button>
    </Stack>
  );
};

export default AccessDeniedPage;
