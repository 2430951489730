import React from 'react';
import CheckPaymentAgreementContent from '../compositions/CheckPaymentAgreementContent';
import ChooseCreditContent from '../compositions/ChooseCreditContent';
import CustomLoading from '../compositions/CustomLoading';
import { useSelectionHook } from '../hooks';

export const PaymentSelectionPage = () => {
  const {
    creditVoucherList,
    balance,
    selectedCreditItem,
    onBackClick,
    onSubmit,
    onSelectItemClick,
    onClose,
    isLoading,
  } = useSelectionHook();

  if (isLoading) return <CustomLoading />;

  return !selectedCreditItem ? (
    //First Page
    <ChooseCreditContent
      creditVoucherList={creditVoucherList}
      balance={balance}
      onselectItemClick={onSelectItemClick}
      onClose={onClose}
    />
  ) : (
    <CheckPaymentAgreementContent
      selectedCredit={selectedCreditItem}
      onBack={onBackClick}
      onSubmit={onSubmit}
      onClose={onClose}
    />
  );
};

export default PaymentSelectionPage;
