import { typography as defaultTypography } from '@imago-cloud/design-system';

const DEFAULT_FONT_LIST = ['Arial', 'Helvetica', 'sans-serif'];

const getFontFamilyByList = (fontList: string[]) => [...fontList, ...DEFAULT_FONT_LIST].join();

export const getFontStyleByLanguage = (language: string) => {
  switch (language) {
    case 'en':
    case 'de':
    case 'es':
    case 'fr':
    case 'it':
    case 'pt': {
      return { fontFamily: getFontFamilyByList(['Inter', 'Noto Sans']) };
    }
    case 'ja': {
      return { fontFamily: getFontFamilyByList(['Inter', 'Noto Sans JP']) };
    }
    case 'ko': {
      return { fontFamily: getFontFamilyByList(['Inter', 'Noto Sans KR']) };
    }
    case 'zh_cn': {
      return { fontFamily: getFontFamilyByList(['Inter', 'Noto Sans SC']) };
    }
    default: {
      return {};
    }
  }
};

export const getDesignSystemFontSet = (language: string) => ({
  typography: {
    ...defaultTypography,
    ...getFontStyleByLanguage(language),
  },
  MuiTypography: {
    styleOverrides: {
      root: {
        ...getFontStyleByLanguage(language),
      },
    },
  },
});
